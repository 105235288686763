import MissionBg from "../../assets/mission-bg.png"
import MissionAsset from "../../assets/Mission.svg"
import AboutMission from "../../assets/AboutMission1.webp"

const Mission = () => {
  return (
    <>
      <div className="flex flex-col w-[100%] bg-[#0648BF] bg-opacity-20 md:flex-row items-center gap-12 p-16  mx-auto">

        
        
        <div className="flex-1 text-left max-w-[503px] ml-[80px] mr-[200px]">
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Our Mission</h2>
          <p className="text-gray-600 text-base md:text-lg leading-relaxed">
            We strive to establish the most dependable worldwide network for clients and partners, 
            offering value through our goods and services. Striving to responsibly generate value for 
            all our stakeholders.
          </p>
        </div>
      
        
        <div className="flex-1  pl-12  ">
          <img
            src={AboutMission}
            alt="Founder"
            className="w-[463px] h-[370px] rounded-tl-lg rounded-tr-none rounded-br-none rounded-bl-none object-cover"
          />
        </div>
      </div>
      
    </>
    
  );
};

export default Mission;