import { useState } from "react";
import Card from "./Card";
import Products from "./Products";
import Trainings from "./Trainings";
import Projects from "./Projects";
import Services from "./Services";
import Consulting from "./Consulting";
import RealEstate from "./RealEstate";

const initialNavigation = [
  { name: "Products", href: "#", current: true, content: <Products /> },
  { name: "Trainings", href: "#", current: false, content: <Trainings /> },
  { name: "Consulting", href: "#", current: false, content: <Consulting /> },
  { name: "Projects", href: "#", current: false, content: <Projects /> },
  { name: "Services", href: "#", current: false, content: <Services /> },
  { name: "Real Estate", href: "#", current: false, content: <RealEstate /> },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Business = (props) => {
  const { businessSection } = props;

  const [navigation, setNavigation] = useState(initialNavigation);

  const handleClick = (name) => {
    setNavigation((prevNavigation) =>
      prevNavigation.map((item) =>
        item.name === name
          ? { ...item, current: true }
          : { ...item, current: false }
      )
    );
  };

  // Find the content of the currently selected item
  const currentItem = navigation.find((item) => item.current);

  return (
    <div
      className="min-h-[876px] flex flex-col items-center justify-center py-12"
      ref={businessSection}
    >
      <h2 className="text-4xl font-pt-sans font-bold">Our Businesses</h2>

      <div className="flex flex-col lg:flex-row w-full max-w-6xl">
        {/* Sidebar */}
        <nav aria-label="Sidebar" className="flex-shrink-0 w-full lg:w-64 p-4">
          <ul
            role="list"
            className="mx-2 md:justify-center justify-start items-center flex md:flex-col lg:items-start h-full space-y-1 overflow-x-auto"
          >
            {navigation.map((item) => (
              <li
                onClick={(e) => {
                  handleClick(item.name);
                }}
                className={classNames(
                  "w-full cursor-pointer",
                  item.current ? "" : "hover:bg-gray-200"
                )}
                key={item.name}
              >
                <div
                  className={classNames(
                    item.current
                      ? "border-l-4 font-semibold border-custom-gold text-custom-gold"
                      : "text-gray-700",
                    "group flex gap-x-3 px-3 text-[28px] font-pt-sans m-4 leading-6 text-nowrap"
                  )}
                >
                  {item.name}
                </div>
              </li>
            ))}
          </ul>
        </nav>

        {/* Main Content */}
        <main className="flex-1 p-6">
          <div className="flex min-h-[564px]">
            <>{currentItem?.content}</>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Business;
