import React from "react";

const Card = ({ title, description, imageUrl, isProduct = false, url }) => {
  return (
    <a
      className="max-w-[230px] overflow-hidden bg-white m-4 cursor-pointer"
      href={url}
      target="_blank"
    >
      <div className="relative">
        <img className="w-full rounded" src={imageUrl} alt={title} />
        {isProduct && (
          <div className="absolute bg-gradient-to-t from-[#3953A499] to-transparent bottom-0 inset-x-0 h-32"></div>
        )}
      </div>

      <div className="py-4">
        <div className="font-regular font-pt-sans text-xl mb-2">{title}</div>
        <p className="text-sm text-gray-400 font-pt-sans">{description}</p>
      </div>
    </a>
  );
};

export default Card;
