"use client";

import React from "react";
import {
  ChartBarIcon,
  AcademicCapIcon,
  UserGroupIcon,
  BriefcaseIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as Products } from "../../assets/Products.svg";
import { ReactComponent as Projects } from "../../assets/Projects.svg";
import { ReactComponent as Services } from "../../assets/Services.svg";
import { ReactComponent as Consulting } from "../../assets/Consulting.svg";
import { ReactComponent as Trainings } from "../../assets/Trainings.svg";
import Honesty from "../../assets/honesty-2.png";
import Trust from "../../assets/integrity-2.png";
import Innovation from "../../assets/Innovation.png";
import Excellence from "../../assets/harmony-2.png";
import Quality from "../../assets/quality-2.png";

const values = [
  {
    title: "Integrity",
    description:
      "Our actions are guided by principles of fairness and honesty. We maintain the highest ethical standards in all our interactions and decisions.",
    icon: <img src={Trust} alt="Honesty" className="w-16 h-16 filter invert" />,
  },
  {
    title: "Trust",
    description:
      "We are committed to integrating environmental sustainability and societal values into our business practices. Our goal is to create a positive and lasting impact on the communities we serve.",
    icon: (
      <img src={Honesty} alt="Honesty" className="w-16 h-16 filter invert" />
    ),
  },
  {
    title: "Innovation",
    description:
      "Embracing boldness and agility, we confront challenges with a deep understanding of our customers. We leverage our insights to develop creative and effective solutions that drive progress.",
    icon: (
      <img src={Innovation} alt="Honesty" className="w-16 h-16 filter invert" />
    ),
  },
  {
    title: "Harmony",
    description:
      "We invest in the growth and development of our team and partners, promoting a culture of continuous learning. Our relationships are built on mutual trust, respect, and collaboration.",
    icon: (
      <img src={Excellence} alt="Honesty" className="w-16 h-16 filter invert" />
    ),
  },
  {
    title: "Excellence",
    description:
      "We strive for excellence in all our endeavors, focusing on delivering superior results. Our commitment to merit-based practices ensures that we continually exceed expectations and set new standards",
    icon: (
      <img src={Quality} alt="Honesty" className="w-16 h-16 filter invert" />
    ),
  },
];

const values1 = [
  {
    title: "Integrity",
    description:
      "Our actions are guided by principles of fairness and honesty. We maintain the highest ethical standards in all our interactions and decisions.",
    icon: <img src={Trust} alt="Honesty" className="w-16 h-16 filter invert" />,
  },
  {
    title: "Trust",
    description:
      "We are committed to integrating environmental sustainability and societal values into our business practices. Our goal is to create a positive and lasting impact on the communities we serve.",
    icon: (
      <img src={Honesty} alt="Honesty" className="w-16 h-16 filter invert" />
    ),
  },
  {
    title: "Innovation",
    description:
      "Embracing boldness and agility, we confront challenges with a deep understanding of our customers. We leverage our insights to develop creative and effective solutions that drive progress.",
    icon: (
      <img src={Innovation} alt="Honesty" className="w-16 h-16 filter invert" />
    ),
  },
  {
    title: "Harmony",
    description:
      "We'll invest in our team and partners, promote ongoing learning, and build caring, cooperative relationships based on mutual trust and respect.",
    icon: (
      <img src={Excellence} alt="Honesty" className="w-16 h-16 filter invert" />
    ),
  },
  {
    title: "Excellence",
    description:
      "Achieving excellence and promoting merit-based practices will be our constant pursuit. Our commitment to merit-based practices ensures to set new standard",
    icon: (
      <img src={Quality} alt="Honesty" className="w-16 h-16 filter invert" />
    ),
  },
];

const Values = () => {
  return (
    <div className="flex flex-col items-center justify-center py-24">
      <h2 className="text-4xl text-[#253C86] font-pt-sans font-bold mb-20">Our Values</h2>

      <div className="flex flex-wrap justify-center gap-5  max-w-8xl ">
        {values1.map((value, index) => (
         <div
         key={index}
         className="flex w-[400px] flex-col items-center text-center m-4 gap-4 hover:scale-110 transition-transform duration-300 ease-in-out shadow-md"
         style={{
           padding: "2px", // Border width
           borderRadius: "8px 8px 40px 8px",
           background: "linear-gradient(180deg, #FFFFFF -51.49%, #253C86 100%)",
           boxSizing: "border-box", // Ensures padding doesn't affect size
         }}
       >
         <div
           className="flex flex-col items-center justify-center h-full" // Centers the icon and content
           style={{
             borderRadius: "8px 8px 40px 8px", // Matches outer border radius
             padding: "30px", // Inner padding
             backgroundColor: "#fff", // Inner background color
             height: "100%", // Prevents gradient from showing inside
           }}
         >
           {/* Icon centered at the top */}
           <div style={{ fontSize: "48px", color: "#253C86" }}>{value.icon}</div>
       
           {/* Title */}
           <h3 className="text-2xl text-[#253C86] font-pt-sans font-bold mt-4">
             {value.title}
           </h3>
       
           {/* Description */}
           <p
             className="text-gray-500 leading-5 md:text-sm text-base font-normal font-pt-sans mt-2 mx-2"
             style={{
               fontSize: "16px",
               color: "#000000",
               lineHeight: "18.9px",
             }}
           >
             {value.description}
           </p>
         </div>
       </div>
       
        ))}
      </div>
    </div>
  );
};

export default Values;
